<div class="form-group">
  <label>{{ passwordLabel | translate }}</label>
  <div [ngClass]="{'is-invalid': password.invalid && password.touched}">
    <app-password-input controlName="password"
                        [form]="passwordForm"
                        [ngClass]="{'is-invalid': password.invalid && password.touched}">
    </app-password-input>
  </div>
  <div class="invalid-feedback">
    <span *ngIf="password.hasError('required')">
      {{ "USER.PASSWORD_REQUIRED_ERROR_MSG" | translate }}
    </span>
    <span *ngIf="password.hasError('pattern')">
      {{ "USER.PASSWORD_FORMAT_ERROR_MSG" | translate }}
    </span>
  </div>
</div>

<div class="form-group">
  <label>{{ repeatPasswordLabel | translate }}</label>
  <div [ngClass]="{'is-invalid': (passwordForm.invalid || repeatPassword.invalid) && repeatPassword.touched}">
    <app-password-input controlName="repeatPassword"
                        [form]="passwordForm"
                        [ngClass]="{'is-invalid': (passwordForm.invalid || repeatPassword.invalid) && repeatPassword.touched}">
    </app-password-input>
  </div>
  <div class="invalid-feedback">
    <span *ngIf="passwordForm.hasError('matchPassword') && !repeatPassword.hasError('required')">
      {{ 'USER.REPEAT_NEW_PASSWORD_NOT_MATCH_ERROR_MSG' | translate }}
    </span>
    <span *ngIf="repeatPassword.hasError('required')">
      {{ 'USER.REPEAT_NEW_PASSWORD_NEED_TO_REPEAT_ERROR_MSG' | translate }}
    </span>
  </div>
</div>
