import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  imports: [ReactiveFormsModule, NgIf],
  standalone: true
})
export class PasswordInputComponent {
  @Input() form: FormGroup;
  @Input() controlName: string;

  displayedPassword: boolean;

  constructor() { }

  toggleDisplayPassword(value: boolean): void {
    this.displayedPassword = value;
  }
}
