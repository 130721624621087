import { FormGroup, ValidationErrors } from '@angular/forms';

export function matchPasswords(form: FormGroup): ValidationErrors | null {
  const password = form.get('password').value;
  const repeatPassword = form.get('repeatPassword').value;

  return password !== repeatPassword
    ? { matchPassword: true }
    : null;
}
