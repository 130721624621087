<form class="password-input-wrapper"
      [formGroup]="form"
      novalidate >
  <input [type]="displayedPassword ? 'text' : 'password'"
        class="form-control"
        autocomplete="new-password"
        [formControlName]="controlName" />
  <i class="icon-eye-view" *ngIf="!displayedPassword" placement="left" (click)="toggleDisplayPassword(true)"></i>
  <i class="icon-eye-hide" *ngIf="displayedPassword" placement="left" (click)="toggleDisplayPassword(false)"></i>
</form>
